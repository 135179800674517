import React, { useEffect, useState } from 'react';
import researchBg from '../assets/images/research-bg.png';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../components/Footer';

interface Blog {
  title: string;
  content: string;
  blogImage?: string;
}

const ResearchDetail: React.FC = () => {
  const [post, setPost] = useState<Blog | null>(null);
  const location = useLocation();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    const fetchPosts = async () => {
      if (!params.id) {
        console.error('No ID provided in params');
        return;
      }

      try {
        const response = await fetch(`https://app.edgeforge.tech/api/v1/admin/blogs/${params.id}`);
        if (response.ok) {
          const data = await response.json();

          setPost(data?.data?.blog ?? null);
        } else {
          console.error('Failed to fetch the blog post.');
        }
      } catch (error) {
        console.error('Error fetching the blog post:', error);
      }
    };

    fetchPosts();
  }, [params.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
    <section className="relative bg-[#101010] py-20 flex flex-col xs:flex-col md:flex-row items-center max-w-custom-1600 mx-auto overflow-visible items-center justify-center lg:mb-[110px]">
      {/* Column for background (Image) */}
      <div className="min-h-[350px] flex flex-col justify-center mt-10 md:mt-0 relative">
        <div>
          <h3 className="text-center uppercase font-mentalist text-[#DCDCDC]/75 text-[7px] sm:text-[12px] md:text-[10px] leading-[10px] font-[700]">Revolutionary hub</h3>
          <h3 className="hidden mt-[100px] text-center font-nexa-heavy font-[800] sm:text-[32px] text-[18px] md:text-[36px] text-[#DCDCDC] leading-[21px] sm:leading-[38px] md:leading-[25px] mb-2">Innovation</h3>
        </div>
        {post ? (
          <div className="m-4 p-2 bg-[#151617] rounded-[20px]">
            <span className="text-white text-4xl font-semibold gradient">{post.title}</span>
            <div className="text-white gradient nexa-Heavy-front border-t-2 pt-2" dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
        ) : (
          <p className="text-[#DCDCDC] text-center">Loading...</p>
        )}
        <img
        //   src={researchBg}
          src={post?.blogImage ? `https://app.edgeforge.tech${post.blogImage}` : researchBg}
          alt="Hero"
          style={{ overflow: 'visible' }}
        />
      </div>
    </section>
      <Footer />
      </>
  );
};

export default ResearchDetail;
