import { twMerge } from "tailwind-merge"
import clsx from "clsx";
import type { ClassValue } from "clsx";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export function formatDate(dateString:any) {
	const date = new Date(dateString);
  
	const months = [
	  "January", "February", "March", "April", "May", "June",
	  "July", "August", "September", "October", "November", "December"
	];
  
	const day = date.getDate();
	const month = months[date.getMonth()];
	const year = date.getFullYear();
  
	// Determine ordinal suffix
	let suffix = "th";
	if (day % 10 === 1 && day !== 11) suffix = "st";
	else if (day % 10 === 2 && day !== 12) suffix = "nd";
	else if (day % 10 === 3 && day !== 13) suffix = "rd";
  
	// Construct the formatted date
	return `${day}${suffix} ${month} ${year}`;
  }