import { Facebook, Linkedin, Instagram, Twitter } from 'lucide-react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import footerLogo from "../assets/images/footer-logo.png";
import headerLogo from "../assets/images/logo.png";

const Footer: React.FC = () => {
  const location = useLocation();
  const handleLinkClick = (path: string) => {
    if (location.pathname === path) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <section className="py-10 my-10 max-w-[1600px] mx-auto px-4 flex flex-col md:flex-row gap-0 bg-custom-zigzag backdrop-blur-sm rounded-[20px] shadow-lg justify-center">
        {/* Left Column: Logo, Main Pages */}
        <div className="md:w-[28%] flex flex-col items-center md:items-start p-8 pt-0 md:pt-8 space-y-6 text-[#DCDCDC]/75">
          {/* Logo */}
          <div className="mb-4">
            <Link to="/" className="uppercase text-white hover:underline" onClick={() => handleLinkClick('/')}>
              <img src={footerLogo} alt="Logo" className="hidden md:flex w-[200px] md:w-[430px] h-auto md:absolute -top-[77px] -left-[25px]" />
              <img src={headerLogo} alt="Logo" className="md:hidden flex w-[300px] md:w-[430px] h-auto md:absolute -top-[77px] -left-[25px]" />
            </Link>
          </div>

          {/* Navigation Heading */}
          <h3 className="font-nexa-heavy text-[21px] leading-[25px] text-[#DCDCDC] mb-2 md:!mt-[185px] lg:!mt-[90px]">Navigation</h3>

          {/* Page List */}
          <ul className="space-y-2 text-center md:text-left">
            <li className="font-nexa-light text-[#DCDCDC]/75 text-[16px] cursor-pointer hover:underline">
              <a href="#about-us">About Us</a>
            </li>
            <li className="font-nexa-light text-[#DCDCDC]/75 text-[16px] cursor-pointer hover:underline">
              <a href="#research">Research</a>
            </li>
            <li className="font-nexa-light text-[#DCDCDC]/75 text-[16px] cursor-pointer hover:underline">
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>

        {/* Right Column: Social Icons, Other Pages */}
        <div className="w-[100%] md:w-[48%] lg:w-[30%] -mt-[50px] md:mt-[90px] lg:mt-[0px] md:pl-[100px] flex flex-col items-center md:items-start p-8 pb-0 md:pb-8 pr-6 space-y-6 text-[#DCDCDC]/75">
          {/* Social Icons */}
          <div className="hidden md:flex space-x-10 mb-4">
            <div className="rounded-full bg-[#DCDCDC00] p-2 cursor-pointer border-[1px] border-[#DCDCDC]/50 hover:bg-[#DCDCDC]/50 transition-colors duration-300">
              <Facebook size={24} className="text-[#DCDCDC]/50" />
            </div>
            <div className="rounded-full bg-[#DCDCDC00] p-2 cursor-pointer border-[1px] border-[#DCDCDC]/50 hover:bg-[#DCDCDC]/50 transition-colors duration-300">
              <Linkedin size={24} className="text-[#DCDCDC]/50" />
            </div>
            <div className="rounded-full bg-[#DCDCDC00] p-2 cursor-pointer border-[1px] border-[#DCDCDC]/50 hover:bg-[#DCDCDC]/50 transition-colors duration-300">
              <Twitter size={24} className="text-[#DCDCDC]/50" />
            </div>
            <div className="rounded-full bg-[#DCDCDC00] p-2 cursor-pointer border-[1px] border-[#DCDCDC]/50 hover:bg-[#DCDCDC]/50 transition-colors duration-300">
              <Instagram size={24} className="text-[#DCDCDC]/50" />
            </div>
          </div>

          {/* Navigation Heading */}
          <h3 className="font-nexa-heavy text-[20px] text-[#DCDCDC] mb-2 text-[21px] leading-[25px] md:!mt-[45px]">Legal</h3>

          {/* Other Pages List */}
          <ul className="space-y-2 text-center md:text-left">
            <li className="font-nexa-light text-[#DCDCDC]/75 text-[16px] cursor-pointer hover:underline">
              <Link to="/terms-and-conditions">
                Terms & Conditions
              </Link>
            </li>
            <li className="font-nexa-light text-[#DCDCDC]/75 text-[16px] cursor-pointer hover:underline">
              <Link to="/privacy-policy">
                Cookie & Privacy Policy
              </Link>
            </li>
          </ul>

          <div className="flex md:hidden space-x-6 !mt-10">
            <div className="rounded-full bg-[#DCDCDC00] p-2 cursor-pointer border-[1px] border-[#DCDCDC]/50 hover:bg-[#DCDCDC]/50 transition-colors duration-300">
              <Facebook size={24} className="text-[#DCDCDC]/50" />
            </div>
            <div className="rounded-full bg-[#DCDCDC00] p-2 cursor-pointer border-[1px] border-[#DCDCDC]/50 hover:bg-[#DCDCDC]/50 transition-colors duration-300">
              <Linkedin size={24} className="text-[#DCDCDC]/50" />
            </div>
            <div className="rounded-full bg-[#DCDCDC00] p-2 cursor-pointer border-[1px] border-[#DCDCDC]/50 hover:bg-[#DCDCDC]/50 transition-colors duration-300">
              <Twitter size={24} className="text-[#DCDCDC]/50" />
            </div>
            <div className="rounded-full bg-[#DCDCDC00] p-2 cursor-pointer border-[1px] border-[#DCDCDC]/50 hover:bg-[#DCDCDC]/50 transition-colors duration-300">
              <Instagram size={24} className="text-[#DCDCDC]/50" />
            </div>
          </div>
        </div>
      </section>
      <div className='text-center mt-14 mb-[110px]'>
        <p className="text-[#DCDCDC]/75 font-nexa-light font-[700] text-[14px] leading-[18px]">
          <span className="text-[#DCDCDC]">EDGEFORCE © 2024</span> - All rights reserved
          <br />
          EDGEFORCE RESEARCH & CONSULTANCY LLC - info@edgeforge.com - Powered by{' '}
          <span className="text-[#DCDCDC] underline">Your Meta Process</span>
        </p>
      </div>
    </>
  );
};

export default Footer;
