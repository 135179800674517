import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import Slider from 'react-slick'; // Ensure this is imported
import researchOne from '../assets/images/1st-research.png';
import researchTwo from '../assets/images/2nd-research.png';
import researchThree from '../assets/images/3rd-research.png';
import arrowRight from '../assets/images/arrow-right.png';
import arrowLeft from '../assets/images/arrow-left.png';
import { formatDate } from "../lib/utils";

const NextArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <div
    className="slick-arrow slick-next absolute !-left-[30px] sm:!-left-[120px] !top-[110px]"
    onClick={onClick}
    style={{ display: 'block', cursor: 'pointer' }}
  >
    <img src={arrowLeft} alt="Menu" className="h-[25px] w-[11px]" />
  </div>
);

const PrevArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <div
    className="slick-arrow slick-prev !-left-[30px] sm:!-left-[120px] !top-[35px]"
    onClick={onClick}
    style={{ display: 'block', cursor: 'pointer' }}
  >
    <img src={arrowRight} alt="Menu" className="h-[25px] w-[11px]" />
  </div>
);
interface BlogPost {
  _id: string;
  blogImage?: string;
  title: string;
  createdAt: string;
  content: string;
}

const OurResearch: React.FC = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState<BlogPost[]>([]);
  console.log("🚀 ~ posts:", posts)

  useEffect(() =>{
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://app.edgeforge.tech/api/v1/admin/blogs');
        if(response.ok) {
          const data = await response.json();

          setPosts(data?.data?.blogs ?? []);
        }
      } catch (error) {
        
      }
    }
    fetchPosts()
  },[])
  const postss = [
    {
      _id: 1,
      image: researchOne,
      createdAt: '27th Jul 2023',
      title: 'Quantum Analysis in Trading',
      content: 'Embark on a data-driven journey through \'Quantum Analysis in Trading.\' Uncover...',
    },
    {
      _id: 2,
      image: researchTwo,
      createdAt: '3rd May 2023',
      title: 'Mindset Reprogramming for Traders',
      content: 'Unleash the true potential of your trading journey with \'Mindset Reprogramming for...',
    },
    {
      _id: 3,
      image: researchThree,
      createdAt: '15th April 2023',
      title: 'Probabilistic Reasoning Mastery',
      content: 'Navigate the complexities of the financial landscape armed with \'Probabilistic Reasoning..',
    },
    {
      _id: 4,
      image: researchTwo,
      createdAt: '3rd May 2023',
      title: 'Mindset Reprogramming for Traders',
      content: 'Unleash the true potential of your trading journey with \'Mindset Reprogramming for...',
    },
    {
      _id: 5,
      image: researchThree,
      createdAt: '15th April 2023',
      title: 'Probabilistic Reasoning Mastery',
      content: 'Navigate the complexities of the financial landscape armed with \'Probabilistic Reasoning..',
    },
    {
      _id: 6,
      image: researchTwo,
      createdAt: '3rd May 2023',
      title: 'Mindset Reprogramming for Traders',
      content: 'Unleash the true potential of your trading journey with \'Mindset Reprogramming for...',
    },
    {
      _id: 7,
      image: researchThree,
      createdAt: '15th April 2023',
      title: 'Probabilistic Reasoning Mastery',
      content: 'Navigate the complexities of the financial landscape armed with \'Probabilistic Reasoning..',
    },
  ];

  const settings = {
    dots: true,
    infinite: posts.length > 1,
    speed: 500,
    slidesToShow: Math.min(3, posts.length), // Adjust slidesToShow based on the number of posts
    slidesToScroll: 1,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: Math.min(1, posts.length), // Adjust for responsive view
        },
      },
    ],
  };


  return (
    <section id="research" className="slick-slider py-20 max-w-[1600px] mx-auto px-4">
      {/* Section Heading */}
      <h2 className="text-center font-nexa-heavy font-[800] text-[32px] sm:text-[48px] text-[#DCDCDC] mb-[70px]">
        Our Research:
      </h2>

      {/* Cards Slider */}
      <div className='relative left-[30px] sm:left-[110px] md:w-[103%]'>
        <Slider {...settings}>
          {posts.map((post) => (
            <div key={post._id} className="bg-[#101010] text-white p-2 rounded-lg shadow-lg flex flex-col pr-[20px] sm:pr-[150px] md:pr-6">
              {/* Card Image with Read More Button */}
              <div className="relative overflow-visible rounded-lg">
                <img src={post.blogImage ? `https://app.edgeforge.tech${post.blogImage}` : researchOne} alt={post.title} className={`${posts.length > 1 ? 'w-full' : ''} max-w-full object-contain h-[180px] rounded-lg border-[1px] rounded-[25px]`} />
                {/* <img src={post.image} alt={post.title} className="w-full h-[180px] object-cover rounded-lg border-[1px] rounded-[25px]" /> */}
                <button onClick={() => navigate(`/research-detail/${post._id}`)} className="absolute -bottom-5 bg-[#DCDCDC] text-[#101010] font-mentalist font-[700] px-9 py-4 text-xs rounded-full shadow-md border-[1px] border-[#DCDCDC] hover:bg-[#101010] hover:text-[#DCDCDC] transition-colors duration-300">
                  Read More
                </button>
              </div>

              {/* Post Date */}
              <p className="text-[#DCDCDC]/75 font-nexa-light font-[400] text-[12px] leading-[14.5px] mt-8">{formatDate(post.createdAt)}</p>

              {/* Post Title */}
              <h3 className="text-[#DCDCDC] font-nexa-heavy font-[800] text-[21px] leading-[25px] mt-2">{post.title}</h3>

              <hr className="hidden md:flex border-t-2 border-white/25 w-[70%] my-4 mt-5" />

              {/* Post Excerpt */}
              <p className="text-[#DCDCDC]/75 font-nexa-light font-[900] text-[16px] leading-[19px] mt-2" dangerouslySetInnerHTML={{ __html: post?.content }}/>
            </div>
          ))}
        </Slider>
      </div>

      <button className="mt-16 flex justify-center items-center text-[#DCDCDC] font-nexa-light font-[700] text-[12px] leading-[14px] mt-auto underline pt-4 mx-auto">
        Read more..
        <ChevronRight size={15} className="ml-[10px]" />
      </button>
    </section>
  );
};

export default OurResearch;
