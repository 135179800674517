import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from './ui/button';
import logo from '../assets/images/logo.png';
import menuIcon from '../assets/images/icon-menu.png';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleLinkClick = (path: string) => {
    if (location.pathname === path) {
      window.scrollTo(0, 0);
      // window.location.reload();
    }
    closeMenu();
  };

  return (
    <header
      className="w-full flex justify-between items-center py-16 xs:py-[52px]"
      onClick={closeMenu}
    >
      {/* Logo */}
      <div className="flex items-center">
        <Link to="/" className="uppercase text-white hover:underline" onClick={() => handleLinkClick('/')}>
          <img src={logo} alt="Website Logo" className="xs:h-[40px] sm:h-[58px]" />
        </Link>
      </div>

      {/* Navigation Menu */}
      <nav className="hidden md:flex space-x-10 font-mentalist font-medium text-[12px]">
        <Link to="/" className="text-white hover:underline" onClick={() => handleLinkClick('/')}>Home</Link>
        <a className="text-white hover:underline" href="#about-us">About Us</a>
        <a className="text-white hover:underline" href="#research">Research</a>
        <a className="text-white hover:underline" href="#contact">Contact</a>
      </nav>

      {/* Hamburger Menu for Mobile */}
      <div
        className="font-mentalist text-white md:hidden flex items-center z-[11]"
        onClick={(e) => e.stopPropagation()} // Prevents event bubbling to header
      >
        <Button onClick={toggleMenu}>
          <img src={menuIcon} alt="Menu" className="xs:h-[16px] xs:w-[30px] sm:h-[23px] sm:w-[40px]" />
        </Button>
      </div>

      {/* Sidebar for Mobile */}
      <div
        className={`fixed top-0 pt-[55px] right-0 w-64 h-full bg-[#101010] shadow-lg z-10 text-white transition-transform duration-300 ease-in-out z-[12] ${isOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        onClick={(e) => e.stopPropagation()} // Prevents sidebar clicks from closing menu
      >
        <div className="font-mentalist flex justify-start p-4 pl-0">
          <Button onClick={toggleMenu}>
            <img src={menuIcon} alt="Close Menu" className="xs:h-[16px] xs:w-[30px] sm:h-[23px] sm:w-[40px]" />
          </Button>
        </div>
        <nav className="font-mentalist flex flex-col space-y-4 p-4">
          <Link to="/" className="text-white hover:underline" onClick={() => handleLinkClick('/')}>Home</Link>
          <a className="text-white hover:underline" href="#about-us" onClick={closeMenu}>About Us</a>
          <a className="text-white hover:underline" href="#research" onClick={closeMenu}>Research</a>
          <a className="text-white hover:underline" href="#contact" onClick={closeMenu}>Contact</a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
