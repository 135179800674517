import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import ResearchDetail from './pages/ResearchDetail';

const App: React.FC = () => {
  return (
    <Router>
      <main className="relative bg-[#101010] flex justify-center items-center flex-col overflow-clip mx-auto xs:px-[25px] sm:px-[44px] md:px-[86px] lg:px-[120px]">
        <div className="max-w-7xl w-full">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/research-detail/:id" element={<ResearchDetail />} />
            <Route path="/privacy-policy" element={<Privacy />} />
          </Routes>
        </div>
      </main>
    </Router>
  );
};

export default App;
